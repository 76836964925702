.details-container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 100vh;
}

.details-info-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.details-img{
    flex-basis: 60%;
    max-width: 550px;
    max-height: 350px;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    object-fit: cover;
}

.details-info{
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    align-items: flex-start;
    padding-left: 35px;
    letter-spacing: 2px;
    position: relative;
}

.details-ratings{
    display: flex;
    align-items: center;
    gap: 5px;
}

.details-star-img{
    width: 20px;
}

.details-name{
    font-size: 25px;
}

.details-price{
    font-size: 25px;
    padding-top: 15px;
}

.details-add-to-cart{
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: white;
    /* background-color: rgb(55, 111, 132); */
    background-color: transparent;
    border: none;
    font-size: 15px;
    border-radius: 11px;
    margin-top: 50px;
}

.review-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 30px 0 100px 25px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    gap: 50px;

}

.detail-page-buttons {
    display: flex;
    list-style: none;
    border: 1px solid rgb(230, 223, 223);
    padding: 8px;
    cursor: pointer;
    border-radius: 22px;
}

.detail-page-buttons:hover{
    background-color:  rgb(136, 193, 213);;
}
