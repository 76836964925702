.search-icon-container {
    list-style: none;
    max-width: 22px;
}

.search-icon-container:hover {
    cursor: pointer;
}

.search-container {
    position: fixed;
    top: 50px;
    left: 60px;
}

.search-input-field {
    height: 35px;
    width: 250px;
    letter-spacing: 2px;
    font-size: 16px;
}

.results-container {
    position: fixed;
    top: 85px;
    left: 61px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
    width: 80vw;
    z-index: 9999;
    overflow-y: scroll;
}

.results-cards {
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 1px;
    border: 2px solid rgb(136, 193, 213);
    padding: 5px;
    border-radius: 12px;
    align-items: stretch;
}

.search-name {
    text-decoration: none;
    color: black;
    letter-spacing: 1px;
}

.search-name:hover {
    color: rgb(57, 129, 155);
}

.search-info {
    display: flex;
    justify-content: space-between;
}

.search-img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 12px;
}

.search-add-to-cart {
    display: flex;
    padding: 5px 0 0 0;
    align-items: flex-end;
    flex-grow: 1;

}

.search-add-button {
    /* width: 150px; */
    width: 100%;
    height: 30px;
    color: white;
    background-color: rgb(40, 96, 117);
    border: none;
    font-size: 15px;
    border-radius: 12px;
}

.search-add-button:hover{
    background-color:rgb(136, 193, 213);
}
