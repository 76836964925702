#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

#modal-content {
    position: absolute;
    background-color: white;
    overflow-y: scroll;
    z-index: 9999;
}

.open-cart-modal-button {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(55, 111, 132);
    border: none;
    font-size: 16px;
    border-radius: 11px;
    margin-top: 50px;
    position: absolute;
    bottom: 1px;
    letter-spacing: 2px;
}

.add-cart-top {
    width: 100% + 12px;
    height: 100%;
    color: white;
    background-color: rgb(40, 96, 117);
    border: none;
    font-size: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.open-cart-modal-button:hover {
    background-color: rgb(136, 193, 213);
}

.add-cart-top:hover {
    background-color: rgb(136, 193, 213);
}
