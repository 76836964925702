.checkout-container {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.checkout-contact-info-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 30px 0;
}

.checkout-login-nav-link {
    width: 360px;
}

.saved-addresses-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 100;
    font-size: 12px;
    width: 400px;
    overflow-x: hidden;
    padding: 0 0 12px 0;
}

#shipping-header {
    padding: 0 0 12px 0;
    text-decoration: underline;
}

#address-list{
    display: flex;
    height: 30px;
    align-items: center;
}

.checkout-address-container {
    display: flex;
    flex-direction: column;
    flex-basis: 55%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.shipping-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
}

.back-to-cart{
    padding: 0 0 20px 0;
}

#back-to-cart:hover{
    color:rgb(79, 141, 163)
}

.shipping-form-container {
    display: flex;
    flex-direction: column;
}


.checkout-cart-container {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    height: 100vh;
    background-color: rgb(242, 236, 236);
    margin-bottom: 20px;
    padding: 40px;
    gap: 20px;
    overflow-y: scroll;
}

.checkout-cart-items {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
}

.checkout-quantity {
    position: absolute;
    bottom: 55px;
    left: 90px;
    background-color: rgb(150, 146, 146);
    color: white;
    padding: 2px;
    width: 20px;
    height: 20xpx;
    text-align: center;
    border-radius: 50%;
}

.cart-page-img {
    width: 100px;
    height: 66px;
    object-fit: cover;
    border-radius: 15px;
}

.checkout-total-price {
    font-size: 15px;
    margin-left: auto;
    padding-right: 150px;
}

.checkout-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: calc(100% - 150px);
    list-style: none;
}

.subtotal-container {
    display: flex;
}

.subtotal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sub-shipping {
    font-size: 15px;
    margin-left: auto;
    padding-right: 150px;
}

.total-price-container {
    display: flex;
    align-items: center;
    font-size: 20px;
    position: sticky;
    bottom: 0;
    background-color: white;
    outline: rgb(79, 141, 163) solid 2px;
    width: calc(100% - 150px);
    padding: 8px;
}

.usd-total {
    display: flex;
    align-items: flex-end;
    font-size: 25px;
    margin-left: auto;
    gap: 10px;
}

#usd {
    font-size: 14px;
    color: rgb(180, 174, 174);
}

.checkout-address-header {
    font-weight: 100;
    font-size: 30px;
    text-align: center;
    padding: 20px 0 20px 0;
}


.checkout-address-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: calc(100%);
    list-style: none;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 100px;
}

#contact-header {
    padding-bottom: 20px;
}

.shipping-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0px;
    /* align-items: flex-start; */
}

.checkout-button-container {
    padding: 30px 0 30px 0;
    position: sticky;
    bottom: 0;
}

.checkout-checkout-button {
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 40px;
    width: 400px;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    margin: auto;
}

.checkout-checkout-button:hover {
    background-color: rgb(136, 193, 213);
}
