
.reviews-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0 0 25px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.review-header{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 22px;
    padding-bottom: 20px;
}

.reviews-cards-container{
    display: flex;
    gap: 30px;
}

.review-card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    word-wrap: break-word;
    border: 1px solid rgb(230, 223, 223);
    width: 200px;
    height: 200px;
    overflow: scroll;
    border-radius: 11px;
}

#user-name{
    font-size: 20px;
    font-weight: bold;
}

#date{
    font-size: 12px;
    color: grey;
}

.star-img{
    width: 15px;
}

.top-star-img{
    width: 25px;
}
