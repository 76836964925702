.produts-container {
    display: flex;
}

.product-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 40vh;
}

.category-banner {
    position: absolute;
    color: white;
    font-size: 50px;
    letter-spacing: 3px;
    text-shadow: 0px 4px 5px rgb(112, 110, 110);
    padding-bottom: 10px;
    border-bottom: 2px solid white;
}

.banner-img {
    width: 100%;
    height: 100%;
}

.product-cards-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 950px;
    margin: auto;
    row-gap: 30px;
    column-gap: 25px;
    padding-bottom: 50px;
}

.sub-category-header {
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 5px;
    color: rgb(40, 96, 117);
    text-shadow: 0px 4px 5px rgb(148, 144, 144);
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-basis: calc(33.33% - 30px);
    width: calc(100% - 66.66%);
    flex-grow: 1;
    height: 100%;
}

.product-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
}

.product-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0 10px 0;
}

#product-origin {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 2px;
}

.product-card-link {
    text-decoration: none;
    color: rgb(54, 124, 152);
}

.product-card-link:hover {
    color: rgb(14, 56, 135);
}

.info-divider {
    list-style: none;
    height: 1px;
    background-color: black;
    width: 8%;
    margin-top: -5px;
}

.inner-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-bottom: 40px;
}

#product-price {
    color: rgb(75, 134, 158)
}

.product-page-avgrating{
    display: flex;
    align-items: center;
    gap: 5px;
}

.the-stars{
    display: flex;
    align-items: center;
}

.product-page-buttons {
    position: relative;
    border: 1px black solid;
    height: 40px;
}

.add-to-cart {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}
