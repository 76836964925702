.address-page-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 100;
    min-height: 100vh;
    margin: auto;
}

.address-page-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0 0 0;
}

#my-address {
    font-size: 20px;
    font-weight: 100;
}

.add-address-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 50px;
    width: 220px;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-left: -3px;
}

.add-address-modal:hover {
    background-color: rgb(136, 193, 213);
    cursor: pointer;
}


.address-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: 100%;
    list-style: none;
}

.address-cards-container {
    display: flex;
    gap: 100px;
    padding: 40px 0 0 0;
}

.address-cards {
    display: flex;
    flex-direction: column;
    padding: 30px 0 0 0;
    gap: 10px;
    font-size: 12px;
    font-weight: 100;
}

.edit-delete-address {
    display: flex;
    gap: 20px;
    list-style-type: none;
    padding: 20px 0 0 0;
    text-decoration: underline;
    font-weight: bold;
}

.modify-address-modal:hover{
    cursor: pointer;
    color: rgb(136, 193, 213);
}

#back-to-account:hover{
    color:rgb(136, 193, 213);
}

.notice-container{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

#notice{
    font-size: 12px;
    letter-spacing: 3px;
}
