/* .splash-page-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 80vh;
    margin: auto;
} */

.splash-header-container {
    background-image: url('../../assets/Splash1.png');
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    letter-spacing: 3px;
    text-shadow: 0px 2px 6px rgb(52, 50, 50);
}

.top-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    letter-spacing: 5px;
}

.splash-header1 {
    letter-spacing: 9px;
}

.splash-header3 {
    letter-spacing: 9px;
}

.splash-header5 {
    letter-spacing: 12px;
}

.splash-header-divider {
    list-style: none;
    height: 3px;
    background-color: white;
    width: 420px;
}

.splash-header-divider-bottom {
    list-style: none;
    height: 3px;
    background-color: white;
    width: 420px;
    margin-bottom: 25px;
}

.splash-bottom-row {
    display: flex;
    flex-direction: row;
}

.splash-bottom-info-container{
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottom-headers{
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 1px;
    color: rgb(40, 96, 117);
    text-align: center;
    width: 60%;
    line-height: 1.6em;
}

.bottom-info{
    font-weight: 100;
    font-size: 16px;
    text-align: center;
    line-height: 1.6em;
    width: 60%;
}

.bottom-img{
    flex-basis: 25%;
}
