.footer {
    width: 100%;
    display: flex;
    background-color: #1a1a1a;
    justify-content: space-evenly;
    padding: 10px 0 10px 0;
}

.github-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.technology-container {
    display: flex;
    color: white;
    align-items: center;
    gap: 5px;
}

.git-links {
    text-decoration: none;
    color: white;
}


.git-links-title {
    color: white;
}

.git-links:hover {
    color: rgb(111, 113, 216);
}

.linkedin-links:hover {
    color: rgb(111, 113, 216);
}

.tech-container-1,
.tech-container-2,
.tech-container-3,
.tech-container-4 {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
}

.tech-outer-container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
