* {
    box-sizing: border-box;
}

#modal-content {
    border-radius: 12px;
}

.review-modal-container {
    padding: 24px;
    width: 500px;
    height: 400px;
    overflow-y: scroll;
}

.review-errors{
    padding-left: 27px;
}


.address-form-container {
    padding: 24px;
    width: 500px;
    height: 600px;
    overflow-y: scroll;
}

.container {
    text-align: left;
    padding: 24px;
    width: 568px;
    height: 620px;
    overflow-y: scroll;
}

.header {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

#cancel-x {
    display: flex;
    font-weight: bold;
    font-size: 15px;
    background-color: white;
    border: none;
}

.header-divider {
    background-color: #ddd;
    height: 1px;
    width: calc(100% + 48px);
    list-style: none;
    margin: 20px -24px 40px;
}

.field-buttons {
    color: white;
    border: none;
    font-size: 18px;
    width: 400px;
    height: 48px;
    margin-top: 30px;
    border-radius: 6px;
    cursor: pointer;
    background-color: rgb(55, 111, 132);
}

.field-buttons:hover{
    background-color: rgb(136, 193, 213);
}

.form-input-bool-container-modal{
    padding: 5px 0 0 25px;
}
