.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-weight: 300;
    font-size: 14px;
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding-top: 100px;
}

.form-header-container:not(back){
    text-align: center;
}

.form-header{
    font-weight: 100;
    letter-spacing: 3px;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    margin:10px auto;
    height: fit-content;
    width: 400px;
}

.form-label {
    position: absolute;
    bottom: calc(100% - 8px);
    left: 10px;
    padding: 0 5px;
    line-height: normal;
    color: #6a6a6a;
    font-size: 12px;
    opacity: 0;
    background: rgba(255,255,255,0);
    pointer-events: none;
    /* transform: translateY(3px);
    transition: all .3s ease-in-out; */
}

input:focus {
    background-color: rgb(220, 239, 246);
    outline: none;
}


.form-input-fields{
    height: 40px;
    font-size: 15px;
    width: 100%;
    padding: 10px;
    border: .5px solid;
}


.form-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-button{
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 40px;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-top: 15px;
}

.form-button:hover {
    background-color: rgb(136, 193, 213);
}


.to-signup{
    display: flex;
    gap: 10px;
}

.sign:hover {
    color:rgb(136, 193, 213)
}

.errors {
    color: rgb(255, 0, 0);
}
