.order-details-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 0;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    letter-spacing: 3px;
    font-weight: 100;
    min-height: 100vh;
}

.order-details-header {
    padding: 20px 0 30px 0;
}

.order-details-header-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#order-head {
    font-size: 20px;
    font-weight: 400;
}

#order-time {
    font-size: 14px;
    font-weight: 200;
}

.order-products {
    width: 700px;
    padding: 0 0 25px 0;
}

.order-products-head {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 10px 0;
    font-size: 12px;
}

.order-qt {
    display: flex;
    gap: 50px;
}

.order-products-cards-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0 20px 0;
}

.order-product-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-info-img-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

.order-img {
    width: 100px;
    height: 66px;
    object-fit: cover;
    border-radius: 10px;
}

.order-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 10px;
}

.order-qt-card {
    display: flex;
    gap: 70px;
    font-size: 12px;
    position: relative
}

/* .order-qt-absolute{
    position: absolute;
    right: 5px;
} */

#quantity-absolute{
    position: absolute;
    right: 120px;
}

.cancel-order {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 50px;
    width: 220px;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-left: -3px;
}

.cancel-order:hover {
    background-color: rgb(136, 193, 213);
    cursor: pointer;
}

.order-subtotal-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 15px;
}

.orders-shipping{
    display: flex;
    text-align: right;
    gap: 52px;
}

.order-total-price-container{
    display: flex;
    text-align: right;
    gap: 30px;
}
