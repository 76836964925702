.empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 14px;
    height: 100vh;
}

.empty-cart-nav-link {
    text-decoration: none;
    background-color: rgb(55, 111, 132);
    color: white;
    border: 1px black solid;
    padding: 15px 25px 15px 25px;
}

.empty-cart-nav-link:hover {
    background-color: rgb(136, 193, 213);
}


.cart-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    letter-spacing: 3px;
}

#cart-header {
    font-weight: 100;
}

.cart-info {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(230, 223, 223);
    margin-bottom: 20px;
    width: 100%;
    color: rgb(150, 143, 143);
    font-size: 12px;
    padding: 70px 0 15px 0;
    justify-content: space-between;
}

.quantity-total {
    display: flex;
    gap: 250px;
}

.cart-cards {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    color: rgb(150, 143, 143);
    font-size: 12px;
    padding-top: 20px;
    justify-content: space-between;
}

.img-info {
    display: flex;
}

.item-info {
    font-size: 14px;
    color: black;
}

.item-info:hover {
    color: rgb(14, 56, 135);
}


.cart-img {
    max-width: 120px;
    width: 100%;
    border-radius: 11px;
}

.info {
    display: flex;
    flex-direction: column;
    padding: 5px 0 0 20px;
    gap: 20px;
}

.item-quantity-total {
    display: flex;
    gap: 230px;
    position: relative;
}

.incart-quantity {
    position: absolute;
    right: 290px;
    top: 18px;
}

.minus-plus {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: 100;
    border: 1px solid rgb(230, 223, 223);
    color: rgb(150, 143, 143);
    width: 100px;
    height: 40px;
    padding: 8px;
}

.minus-plus>button {
    background-color: white;
    border: none;
    font-size: 18px;
    color: rgb(150, 143, 143);
}

.remove-button{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    border: none;
    color:rgb(150, 143, 143);
    text-decoration: underline;
    padding: 10px 0 2px 0 ;
    letter-spacing: 2px;
    font-size: 12px;
}

.total-checkout{
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    margin-left: auto;
}

.total-price{
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    right: 1px; */
}

.checkout-button{
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 40px;
    width: 100%;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    margin: 15px 0 50px;
}
