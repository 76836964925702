body {
    margin: 0;
    font-family: Nunito Sans, sans-serif;
}

button:hover {
    cursor: pointer;
}

.main-container {
    min-height: 100vh;
}
