.nav-link:hover {
    cursor: pointer;
}

.nav-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    height: 50px;
    padding: 5px 60px 5px 60px;
    /* width: 100vw; */
    border-bottom: 1px solid rgb(209, 204, 204);
    background-color: white;
    z-index: 10;
}

.product-list {
    display: flex;
    align-items: center;
}

.nav-link {
    display: flex;
    gap: 10px;
    color: #1C1B1B;
    font-weight: 550;
    font-size: 14px;
    letter-spacing: 1px;
}

.nav-link:link {
    text-decoration: none;
}

.nav:hover {
    border-bottom: 3px solid rgb(136, 193, 213);
}

.search-icon:hover {
    border-bottom: 3px solid rgb(136, 193, 213);
}

.fish-icon {
    width: 40px;
}

.search-icon-container {
    width: 123px;
}

.search-icon {
    width: 18px;
}

.cart-icon {
    width: 30px;
}

.user-icon {
    width: 25px;
}

.product-list {
    display: flex;
    gap: 15px;
}

.cart-user {
    display: flex;
    align-items: center;
    gap: 15px;
}

.nav-cart {
    position: relative;
}

.num-in-cart {
    position: absolute;
    left: 7px;
    font-size: 12px;
    min-width: 20px;
    text-align: center;
}
