.profile-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    letter-spacing: 3px;
    font-weight: 100;
    min-height: 100vh;
}

.profile-info-container {
    display: flex;
}

.order-history-container {
    display: flex;
    flex-basis: 60%;
    padding-top: 60px;
}

.address-info-container {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    padding: 60px 0 0 0;
    font-size: 12px;
    gap: 10px;
}

.primary-address-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 0 0 0;
}

.address-button {
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 40px;
    width: 200px;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-top: 15px;
}

.address-button:hover {
    background-color: rgb(136, 193, 213);
}


.profile-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: calc(100% - 100px);
    list-style: none;
}

.profile-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

#my-account {
    font-size: 30px;
    font-weight: bold;
}

.logout-button {
    color: white;
    background-color: rgb(79, 141, 163);
    border: 1px black solid;
    height: 30px;
    width: 100px;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-top: 15px;
}

.logout-button:hover {
    background-color: rgb(136, 193, 213);
}

.order-history-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 100;
    padding: 60px 50px 0 0;

}

.orders-header {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 0 0 10px 0;
}

.order-date {
    display: flex;
    gap: 100px;
}

.order-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: 100%;
    list-style: none;
}

.order-date-divider {
    background-color: rgb(194, 186, 186);
    height: 1px;
    width: 100%;
    list-style: none;
}

.orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 0 0;
    font-size: 12px;
    overflow-y: scroll;
}

.order-cards {
    display: flex;
}

.order-date-card {
    display: flex;
    gap: 88px;
}

.orders-nav-link {
    color: black;
}

.order-total-price{
    margin-left: auto;
}

.orders-nav-link:hover {
    color: rgb(136, 193, 213);
}
